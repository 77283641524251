import { swiftly } from "../client-data-bom"

const isEmptyAd = (ad: swiftly.ads.SwiftlyJsResolvableAdWrapper) => {
  const constructorName =
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (ad.ad as any)?.constructorName || ad.ad?.constructor.name
  return constructorName === "SwiftlyJsEmptyAdValue"
}

export default isEmptyAd
