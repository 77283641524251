import React, { ReactNode, useEffect, useRef, useState } from "react"
import SubCategories from "./subCategories/SubCategories"
import { useTranslation } from "react-i18next"
import Ads from "../ads/Ads"
import { useClaimCoupon } from "components/coupons/Coupons"
import {
  Box,
  Button,
  Container,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import {
  CouponList,
  CouponListItem,
  ProductList,
  ProductListItem,
} from "swiftlykit"
import {
  setOpenCouponId,
  setOpenProductId,
  setReFetchedCoupons,
} from "../../contexts/actions/actions"
import { findBestDecoration, findOfferState } from "../../utils/offers"
import { useRouter } from "next/router"
import styles from "../../styles/component.module.scss"
import { swiftly } from "../../client-data-bom"
import { useAppContext } from "../../contexts/AppContext"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import generateConfig from "../../configs/config"
import { headingStyles, productCardDisplayMessage } from "./ItemList"
import Cookies from "js-cookie"

export interface ItemListProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  merchandisedCategory: any
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  merchandisedDisplayableObjects: any[]
  storeId?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  graphAsMap: any
  devicePixelRatio: number
  userId?: string
  refetchData?: () => Promise<void>
  overrideHandling?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    merchandisedDisplayableObject: any
  ) => ReactNode | undefined | null
  showTitle?: boolean
  showSubcategories?: boolean
}

const config = generateConfig()

// TODO: add proper types
const Merchandised = ({
  merchandisedCategory,
  storeId,
  merchandisedDisplayableObjects,
  graphAsMap,
  devicePixelRatio,
  userId,
  refetchData,
  overrideHandling,
  showTitle,
  showSubcategories,
}: ItemListProps) => {
  const { t } = useTranslation()
  const { isClaiming, claimCoupon } = useClaimCoupon()
  const {
    dispatch,
    state: { reFetchedCoupons },
  } = useAppContext()
  const router = useRouter()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(800))
  const claimCouponRef = useRef<string | null>()
  const [showGAandGTM, setShowGAandGTM] = useState(false)

  useEffect(() => {
    const cookieValue = Cookies.get("cmapi_cookie_privacy")
    /**
     * cookieValue = 2 means Functional Cookies are set to true
     */
    if (cookieValue && !cookieValue.includes("2")) {
      setShowGAandGTM(false)
    } else {
      setShowGAandGTM(true)
    }
  }, [])

  return (
    <>
      {showTitle && (
        <Container maxWidth="xl">
          <Typography
            variant="h1"
            fontWeight={600}
            mb={2}
            sx={{
              ...headingStyles.h1,
            }}
          >
            {merchandisedCategory?.displayName}
          </Typography>
        </Container>
      )}
      {showSubcategories && (
        <Container maxWidth="xl">
          <SubCategories
            merchandisedCategory={merchandisedCategory}
            categories={merchandisedCategory.browseSubcategories}
            storeId={storeId}
          />
        </Container>
      )}

      {merchandisedDisplayableObjects?.length > 0 &&
        merchandisedDisplayableObjects
          .map(
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (merchandisedDisplayableObject: any, i: number) => {
              if (overrideHandling) {
                const possibleElement = overrideHandling(
                  merchandisedDisplayableObject
                )
                if (possibleElement) {
                  return possibleElement
                }
              }

              const ads = merchandisedDisplayableObject?.ad ? (
                <Container maxWidth="xl">
                  <Ads
                    adWrapper={merchandisedDisplayableObject}
                    devicePixelRatio={devicePixelRatio}
                    userId={userId}
                    key={`display-${i}-ads`}
                    overrideSx={{
                      width: "100%",
                    }}
                  />
                </Container>
              ) : (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ((merchandisedDisplayableObject?.ads as any) || []).map(
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  (ad: any, index: number) => {
                    return (
                      <Container maxWidth="xl" key={index}>
                        <Ads
                          adWrapper={ad}
                          devicePixelRatio={devicePixelRatio}
                          userId={userId}
                          key={`display-${i}-ads-${index}`}
                          overrideSx={{
                            width: "100%",
                          }}
                        />
                      </Container>
                    )
                  }
                )
              )

              if (
                merchandisedDisplayableObject?.items &&
                merchandisedDisplayableObject?.items?.length
              ) {
                const inlineAds = isMobile
                  ? null
                  : merchandisedDisplayableObject?.ads?.length > 0 &&
                    merchandisedDisplayableObject?.ads[0]
                return (
                  <Box
                    sx={{ marginTop: 2 }}
                    key={i}
                    className={styles.productC}
                  >
                    <ProductList
                      showScrollButtons={true}
                      title={
                        graphAsMap[merchandisedDisplayableObject.taxonomyNodeId]
                          ?.displayName ||
                        merchandisedDisplayableObject.taxonomyNodeId
                      }
                      titleChild={
                        <Button
                          variant="text"
                          sx={{
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "flex-end",
                            textDecoration: "underline",
                            fontSize: 14,
                            fontWeight: 500,
                            color: "ctaPrimary.main",
                          }}
                          role="link"
                          onClick={() => {
                            router
                              .push(
                                `/categories/${encodeURIComponent(
                                  merchandisedDisplayableObject.taxonomyNodeId
                                )}`
                              )
                              .catch(console.error)

                            gaLogEvent({
                              eventName: EventName.navPage_viewAllClick,
                              parameters: {
                                item_nameCategory: "Categories",
                                item_nameCategorySub:
                                  graphAsMap[
                                    merchandisedDisplayableObject.taxonomyNodeId
                                  ]?.displayName,
                              },
                            })
                          }}
                          aria-label={t("home.viewAll")}
                        >
                          {t("home.viewAll")}
                        </Button>
                      }
                      listChildBefore={
                        inlineAds && (
                          <Ads
                            adWrapper={inlineAds}
                            devicePixelRatio={devicePixelRatio}
                            userId={userId}
                            overrideSx={{
                              overflow: "clip",
                              height: "360px",
                            }}
                          />
                        )
                      }
                      nodeBelowTitle={isMobile ? ads : null}
                      cards={merchandisedDisplayableObject?.items?.map(
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (item: any) => {
                          const card: ProductListItem = {
                            id: item.id,
                            brand: item?.brand || "",
                            title: item?.brand || "",
                            shortDescription: item?.title || "",
                            // eligibilites to be updated in swiftlykit first
                            // eligibilities: item?.eligibilities || [],
                            imageUrl: showGAandGTM
                              ? item.images.find(
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  (image: any) => !!image.image
                                )?.image?.url
                              : item.images
                                  .find(
                                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                    (image: any) => !!image.image
                                  )
                                  ?.image?.url.includes("cloudfront")
                              ? ""
                              : item.images.find(
                                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                                  (image: any) => !!image.image
                                )?.image?.url,
                            type: "product",
                            ...(config.experimentalProductPricing === "true"
                              ? {
                                  basePrice: item?.webPrice?.success?.basePrice,
                                  isSale:
                                    item?.webPrice?.success?.isSale ?? false,
                                  promoPrice:
                                    item?.webPrice?.success?.promoPrice,
                                  sx: {
                                    card: {
                                      height: "342px !important", // forcing height for experimental purposes
                                    },
                                  },
                                  displayMessage: productCardDisplayMessage(
                                    item,
                                    t("products.modal.StoreNotSelected")
                                  ),
                                }
                              : {}),
                            onCardClick: (id: string) => {
                              dispatch(setOpenProductId(id))
                            },
                          }
                          return card
                        }
                      )}
                      cardWidth={180}
                      cardGap={16}
                    />
                  </Box>
                )
              }

              if (
                merchandisedDisplayableObject?.offers &&
                merchandisedDisplayableObject?.offers?.length
              ) {
                const inlineAds = isMobile
                  ? null
                  : merchandisedDisplayableObject?.ads?.length > 0 &&
                    merchandisedDisplayableObject?.ads[0]
                return (
                  <Box
                    className={styles.couponsC}
                    key={i}
                    sx={{
                      ".sk-CouponCard .sk-CouponCard__card-container .MuiTypography-h3":
                        {
                          color: `${theme.palette.ctaPrimary.main}`,
                        },
                    }}
                  >
                    <CouponList
                      showScrollButtons={true}
                      title={
                        graphAsMap[merchandisedDisplayableObject.taxonomyNodeId]
                          ?.displayName ||
                        merchandisedDisplayableObject.taxonomyNodeId
                      }
                      titleChild={
                        <Button
                          variant="text"
                          sx={{
                            marginLeft: "auto",
                            display: "flex",
                            alignItems: "flex-end",
                            textDecoration: "underline",
                            fontSize: 14,
                            fontWeight: 500,
                            color: "ctaPrimary.main",
                          }}
                          role="link"
                          aria-label={t("home.viewAll")}
                          onClick={() => {
                            router
                              .push(
                                `/coupons/${graphAsMap[
                                  merchandisedDisplayableObject.taxonomyNodeId
                                ]?.displayName?.toLowerCase()}`
                              )
                              .catch(console.error)
                            gaLogEvent({
                              eventName: EventName.navPage_viewAllClick,
                              parameters: {
                                item_nameCategory: "Coupons",
                                item_nameCategorySub:
                                  graphAsMap[
                                    merchandisedDisplayableObject.taxonomyNodeId
                                  ]?.displayName,
                              },
                            })
                          }}
                        >
                          {t("home.viewAll")}
                        </Button>
                      }
                      listChildBefore={
                        inlineAds ? (
                          <Ads
                            adWrapper={inlineAds}
                            devicePixelRatio={devicePixelRatio}
                            userId={userId}
                            overrideSx={{
                              overflow: "clip",
                              height: "360px",
                            }}
                          />
                        ) : null
                      }
                      nodeBelowTitle={isMobile ? ads : null}
                      cards={merchandisedDisplayableObject?.offers?.map(
                        (c: swiftly.offers.SwiftlyJsSwiftlyOffer) => {
                          c =
                            reFetchedCoupons.find(
                              (coupon: any) => coupon.id === c.id
                            ) ||
                            reFetchedCoupons.find(
                              (coupon: any) =>
                                coupon.id?.indexOf(`${c.id}-`) > -1 ||
                                c.id?.indexOf(`${coupon.id}-`) > -1
                            ) ||
                            c
                          const card: CouponListItem = {
                            type: "coupon",
                            id: c.id,
                            brand: c.brand,
                            valueText: c.valueText,
                            shortDescription: c.shortDescription,
                            imageUrl:
                              c.images &&
                              c.images.length > 0 &&
                              c.images[0].imageInfo &&
                              c.images[0].imageInfo.length > 0
                                ? showGAandGTM
                                  ? c.images[0].imageInfo[0].url
                                  : c.images[0].imageInfo[0].url.includes(
                                      "cloudfront"
                                    )
                                  ? ""
                                  : c.images[0].imageInfo[0].url
                                : "",
                            expDate: new Date(c.expirationDateStr),
                            onCouponClick: () => {
                              gaLogEvent({
                                eventName: EventName.coupon_view,
                                parameters: {
                                  item_nameCategory:
                                    graphAsMap[
                                      merchandisedDisplayableObject
                                        .taxonomyNodeId
                                    ]?.displayName ||
                                    merchandisedDisplayableObject.taxonomyNodeId,
                                  imageUrl:
                                    c?.images[0].imageInfo[0].url &&
                                    c?.images[0].imageInfo[0].url,
                                  item_brandName: c.brand,
                                  item_description: c.offerDescription,
                                  item_savings: c.valueText,
                                  item_location: window.location.pathname,
                                  item_message1: findBestDecoration(c),
                                  item_expirationDate: `${String(
                                    new Date(c.expirationDateStr).getMonth() + 1
                                  ).padStart(2, "0")}/${String(
                                    new Date(c.expirationDateStr).getDate()
                                  ).padStart(2, "0")}`,
                                },
                              })
                              !isClaiming && dispatch(setOpenCouponId(c.id))
                            },
                            topBar: findBestDecoration(c),
                            state:
                              isClaiming && claimCouponRef.current === c.id
                                ? "claiming"
                                : findOfferState(c),
                            onClipClick: () => {
                              gaLogEvent({
                                eventName: EventName.coupon_clip,
                                parameters: {
                                  item_nameCategory:
                                    graphAsMap[
                                      merchandisedDisplayableObject
                                        .taxonomyNodeId
                                    ]?.displayName ||
                                    merchandisedDisplayableObject.taxonomyNodeId,
                                  item_nameCategorySub: "",
                                  item_brandName: c.brand,
                                  item_description: c.shortDescription,
                                  imageUrl:
                                    c?.images[0].imageInfo[0].url &&
                                    c?.images[0].imageInfo[0].url,
                                  item_savings: c?.valueText,
                                  item_location: "Card",
                                  item_message1: findOfferState(c),
                                  item_expirationDate: `${String(
                                    new Date(c.expirationDateStr).getMonth() + 1
                                  ).padStart(2, "0")}/${String(
                                    new Date(c.expirationDateStr).getDate()
                                  ).padStart(2, "0")}`,
                                },
                              })
                              claimCouponRef.current = c.id
                              if (findOfferState(c) === "active") {
                                claimCoupon(
                                  c.id,
                                  false,
                                  (cp) => {
                                    if (cp?.id) {
                                      dispatch(
                                        setReFetchedCoupons([
                                          ...reFetchedCoupons.filter(
                                            (coupon: any) =>
                                              coupon.id !== cp?.id
                                          ),
                                          cp,
                                        ])
                                      )
                                    } else {
                                      // fall back: update state to claimed
                                      const coupon = {
                                        ...c,
                                        id:
                                          c.id.split("-").length > 2
                                            ? c.id
                                                .split("-")
                                                .slice(0, -1)
                                                .join("-")
                                            : c.id,
                                        shopperOffer: {
                                          ...c.shopperOffer,
                                          claimed: true,
                                        },
                                      } as swiftly.offers.SwiftlyJsSwiftlyOffer
                                      dispatch(
                                        setReFetchedCoupons([
                                          ...reFetchedCoupons.filter(
                                            (coupon: any) => coupon.id !== c.id
                                          ),
                                          coupon,
                                        ])
                                      )
                                    }
                                    dispatch(
                                      refetchData?.()?.then(() => {
                                        setReFetchedCoupons([])
                                      })
                                    )
                                    claimCouponRef.current = null
                                  },
                                  () => {
                                    claimCouponRef.current = null
                                  }
                                )
                              }
                            },
                          }
                          return card
                        }
                      )}
                      cardWidth={
                        // change this when cardWidth changes
                        180
                      }
                      cardGap={
                        // change this when cardGap changes
                        16
                      }
                    />
                  </Box>
                )
              }
              return null
            }
          )
          .map((item, i) => {
            return (
              <Container maxWidth="xl" key={i} data-cy="merchandised-products">
                {item}
              </Container>
            )
          })}
    </>
  )
}

export default Merchandised
