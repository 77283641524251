import { Box, Container, Stack, Typography, useMediaQuery } from "@mui/material"
import {
  DisplayableObject,
  ProductSummary,
  TaxonomyNode,
} from "../../generated/graphql"
import { EventName, gaLogEvent } from "utils/googleAnalyticsEvents"
import { ReactNode, useContext } from "react"

import Ads from "../ads/Ads"
import AdsPlaceHolder from "../ads/AdsPlaceHolder"
import { AppContext } from "contexts/AppContext"
import { BreadcrumbProp } from "components/breadcrumb/Breadcrumb"
import { CategoryAvatar } from "swiftlykit"
import ItemList from "./ItemList"
import Merchandised from "./Merchandised"
import { MerchandisedCategoryType } from "pages/categories/[categoryId]"
import PropTypes from "prop-types"
import WeeklyAd from "./WeeklyAd"
import isEmptyAd from "../../utils/isEmptyAd"
import { resetPageAttributes } from "contexts/actions/actions"
import styles from "../../styles/component.module.scss"
import { swiftly } from "../../client-data-bom"
import { useAuth } from "contexts/AuthContext"
import { useRouter } from "next/router"
import { useTheme } from "@emotion/react"
import { useTranslation } from "react-i18next"

export type MerchandisedCategory =
  | (swiftly.taxonomy.SwiftlyJsMerchandisedCategoryInfo & {
      constructorName: string
      childNodes: TaxonomyNode[]
      displayableObjects: DisplayableObject[]
      products: {
        products: ProductSummary[]
      }
      ads: Array<swiftly.ads.SwiftlyJsResolvableAdWrapper>
    })
  | {
      childNodes: string[]
      constructorName: string
      taxonomy: swiftly.taxonomy.SwiftlyJsTaxonomy
      heroTopAdRef?: string
      heroBottomAdRef?: string
      ads: Array<any>
      products: {
        products: ProductSummary[]
      }
      displayableObjects: DisplayableObject[]
    }

interface CategoriesProps {
  // merchandisedCategory: MerchandisedCategory
  merchandisedCategory: MerchandisedCategoryType
  storeId?: string
  devicePixelRatio: number
  refetchData?: () => Promise<void>
  overrideHandling?: (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    merchandisedDisplayableObject: any
  ) => ReactNode | undefined | null
  showTitle?: boolean
  showSubcategories?: boolean
  isLoading?: boolean
  setSubCategories?: (subCategories: string[]) => void
  breadcrumbs?: BreadcrumbProp[]
}

export const getImageUrl = (images: any[]) => {
  return (
    images
      .map((image) => {
        return image?.images
          .map((imageInfo: any) => {
            const imageUrl = imageInfo.uri

            if (imageUrl) {
              return imageUrl
            }
            return false
          })
          .find((url: string) => !!url)
      })
      .find((url) => !!url) ?? ""
  )
}

export default function Categories({
  merchandisedCategory,
  storeId,
  devicePixelRatio,
  refetchData,
  overrideHandling,
  showTitle,
  showSubcategories,
  isLoading,
  setSubCategories,
  breadcrumbs,
}: CategoriesProps) {
  const router = useRouter()
  const theme = useTheme()
  const { userId } = useAuth()
  const { t } = useTranslation()

  const isMobile = useMediaQuery(theme.breakpoints.down(767))
  const isDesktop = useMediaQuery(theme.breakpoints.up(1280))

  const { state, dispatch } = useContext(AppContext)

  const graphAsMap = merchandisedCategory?.taxonomy?.graph.reduce(
    (acc, node) => {
      acc[node.id] = node
      return acc
    },
    {} as { [key: string]: TaxonomyNode }
  )

  const menuClicked = (categoryId: string) => {
    dispatch(resetPageAttributes())
    const categoryIdSanitized = encodeURIComponent(categoryId)
    const url = storeId
      ? `/stores/${storeId}/categories/${categoryIdSanitized}`
      : `/categories/${categoryIdSanitized}`
    router.push(url).catch(console.error)
  }

  const handleCategoryClick = (e: any, categoryId: string) => {
    if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
      menuClicked(categoryId)
      gaLogEvent({
        eventName: EventName.navProdCat_click,
        parameters: {
          item_nameCategory: categoryId.split("/")[3],
          item_location: "Single Page",
        },
      })
    }
  }

  const topAdRef = merchandisedCategory.heroTopAdRef
  const bottomAdRef = merchandisedCategory.heroBottomAdRef
  const topAd = merchandisedCategory.ads.find((item) => {
    return item.ad?.aid === topAdRef || item.ad.adId === topAdRef
  })

  if (
    merchandisedCategory?.adList &&
    merchandisedCategory?.adList[0]?.ad.placements
  ) {
    const bannerListArray = merchandisedCategory?.adList[0]?.ad.placements.map(
      (item: any) => {
        return item.adRef
      }
    )

    localStorage.setItem("bannerListArray", JSON.stringify(bannerListArray))
  }
  const bottomAd = merchandisedCategory.ads.find(
    (item) => item.ad?.aid === bottomAdRef || item.ad.adId === bottomAdRef
  )
  switch (merchandisedCategory.constructorName) {
    case "SwiftlyJsMerchandisedCategoryMenuInfoValue":
      return (
        <>
          <Ads
            adWrapper={topAd}
            devicePixelRatio={devicePixelRatio}
            userId={userId}
            showEmptyAdHint={true}
          />
          <Typography
            variant="h1"
            textAlign={isMobile ? "left" : "center"}
            mb={isMobile ? 2 : 6}
            fontWeight={600}
            fontSize={isMobile ? "20px" : "24px"}
            mt={2}
          >
            {t("categories.title")}
          </Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: `repeat(auto-fit, minmax(${
                isMobile ? 100 : isDesktop ? 250 : 200
              }px, 1fr))`,
              width: "100%",
              gap: `${isMobile ? 1 : 3}`,
            }}
            mb={isMobile ? 2 : 6}
          >
            {merchandisedCategory.childNodes.map((categoryId: any, idx) => {
              const displayName =
                graphAsMap?.[
                  categoryId as keyof {
                    [key: string]: swiftly.taxonomy.SwiftlyJsTaxonomyNode
                  }
                ].displayName
              return (
                <Box
                  key={idx}
                  onClick={(e) => handleCategoryClick(e, categoryId)}
                  onKeyDown={(e) => handleCategoryClick(e, categoryId)}
                  aria-label={displayName}
                  tabIndex={0}
                  role="link"
                  data-cy="category-avatar"
                >
                  <CategoryAvatar
                    height={isMobile ? 80 : isDesktop ? 160 : 120}
                    width={isMobile ? 80 : isDesktop ? 160 : 120}
                    key={`category-${categoryId}`}
                    name={displayName}
                    imgURL={getImageUrl(
                      graphAsMap?.[
                        categoryId as keyof {
                          [key: string]: swiftly.taxonomy.SwiftlyJsTaxonomyNode
                        }
                      ].images as any[]
                    )}
                    // eslint-disable-next-line
                    showBorder={false}
                    variant={(isMobile ? "label" : "h5") as any}
                  />
                </Box>
              )
            })}
          </Box>
          <Ads
            showEmptyAdHint={true}
            adWrapper={bottomAd}
            devicePixelRatio={devicePixelRatio}
            userId={userId}
          />
        </>
      )

    case "SwiftlyJsProductsMerchandisedCategoryItemListInfoValue":
    case "SwiftlyJsOffersMerchandisedCategoryItemListInfoValue":
    case "SwiftlyJsMerchandisedCategoryItemListInfoValue":
      return (
        <ItemList
          userId={userId}
          topAd={topAd}
          bottomAd={bottomAd}
          merchandisedCategory={merchandisedCategory}
          products={
            merchandisedCategory?.products?.products as ProductSummary[]
          }
          storeId={storeId}
          isLoading={isLoading}
          setSubCategories={setSubCategories}
          breadcrumbs={breadcrumbs}
        />
      )
    case "SwiftlyJsOffersMerchandisedCategoryMerchandisedInfoValue":
    case "SwiftlyJsMerchandisedCategoryMerchandisedInfoValue":
      return (
        <>
          {topAd?.ad && !isEmptyAd(topAd) ? (
            <Container maxWidth="xl" sx={{ mt: 3 }}>
              <Stack
                direction="row"
                gap={3}
                className={styles.bannerTopSection}
              >
                <Ads
                  showEmptyAdHint={true}
                  adWrapper={topAd}
                  devicePixelRatio={devicePixelRatio}
                  userId={userId}
                />
                {isDesktop && <WeeklyAd />}
              </Stack>
            </Container>
          ) : (
            topAd === undefined && (
              <Container maxWidth="xl" sx={{ mt: 2 }}>
                <Stack direction="row" gap={2}>
                  <AdsPlaceHolder />
                </Stack>
              </Container>
            )
          )}
          <Merchandised
            merchandisedCategory={merchandisedCategory}
            merchandisedDisplayableObjects={
              merchandisedCategory?.displayableObjects
            }
            storeId={storeId}
            graphAsMap={graphAsMap}
            devicePixelRatio={devicePixelRatio}
            userId={userId}
            refetchData={refetchData}
            overrideHandling={overrideHandling}
            showTitle={showTitle}
            showSubcategories={showSubcategories}
          />
          {bottomAd?.ad && (
            <Container maxWidth="xl" sx={{ mt: 4 }}>
              <Ads
                showEmptyAdHint={true}
                adWrapper={bottomAd}
                devicePixelRatio={devicePixelRatio}
                userId={userId}
              />
            </Container>
          )}
        </>
      )
    default:
      return <>{`unhandled type ${merchandisedCategory.constructorName}`}</>
  }
}

Categories.propTypes = {
  merchandisedCategory: PropTypes.object.isRequired,
  storeId: PropTypes.string,
}
