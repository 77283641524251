import React from "react"
import Image from "next/image"
import smAdImage from "../../assets/weeklyad/savemart.png"
import luAdImage from "../../assets/weeklyad/lucky.png"
import fmAdImage from "../../assets/weeklyad/foodmax.png"

import { Box, Button, Link, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useAppContext } from "../../contexts/AppContext"
import { useRouter } from "next/router.js"
import { useAuth } from "contexts/AuthContext"
import { useTheme } from "@mui/material/styles"

const WeeklyAd = () => {
  const { t } = useTranslation()
  const router = useRouter()
  const {
    state: { currentStore },
  } = useAppContext()

  const theme = useTheme()

  const { initialState } = useAuth()

  let adImage
  switch (initialState?.envVariables?.configBanner) {
    case "savemart":
      adImage = smAdImage
      break
    case "luckysupermarkets":
      adImage = luAdImage
      break
    case "foodmaxx":
      adImage = fmAdImage
      break
    default:
      // get a real default theme and put it here...sprys??
      adImage = smAdImage
  }

  const handleSelectStore = (e: any) => {
    if (e.type === "click" || (e.type === "keydown" && e.key === "Enter")) {
      router.push(`/stores?flyer`)
    }
  }

  return (
    <Box
      tabIndex={0}
      sx={{
        borderRadius: ".75rem",
        border: "1px solid rgba(45, 64, 62, 0.16)",
        display: "flex",
        flexDirection: "column",
        p: 3,
        width: 330,
        alignItems: "center",
        // justifyContent: "space-around",
      }}
    >
      {currentStore?.number ? (
        <>
          {/*TODO: replace with actual weekly ad*/}
          <Image
            style={{
              marginTop: "auto",
            }}
            src={adImage}
            alt="weekly ad"
            width={366}
            height={234}
          />
          <Button
            tabIndex={0}
            color="ctaPrimary"
            sx={{
              marginTop: "auto",
              fontSize: 14,
              fontWeight: 400,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => router.push(`/flyers/${currentStore?.number}`)}
            aria-label={t("common.weeklyAd")}
          >
            {t("common.weeklyAd")}
          </Button>
        </>
      ) : (
        <>
          <Image
            src={adImage}
            alt="weekly ad"
            width={366}
            height={234}
            style={{
              marginTop: "auto",
            }}
          />
          <Typography
            sx={{
              marginTop: 2,
              textAlign: "center",
            }}
            mb={1}
            variant="h5"
          >
            {t("common.weeklyAd")}
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "textSecondary.main",
            }}
            variant="body1"
            fontWeight={400}
          >
            {t("weeklyAd.selectStoreToSeeWeeklyAd")}
          </Typography>
          <Link
            tabIndex={0}
            sx={{
              mt: 1,
              fontSize: 14,
              fontWeight: 400,
              cursor: "pointer",
              color: `${theme.palette.ctaPrimary?.main}`,
              textDecoration: "underline",
            }}
            onClick={handleSelectStore}
            onKeyDown={handleSelectStore}
            role="button"
          >
            {t("common.selectStore")}
          </Link>
        </>
      )}
    </Box>
  )
}

export default WeeklyAd
