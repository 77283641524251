const htmlEncode = (str: string) =>
  str.replace(/[&<>'"]/g, (tag: string) =>
    String(
      {
        "&": "&amp;",
        "<": "&lt;",
        ">": "&gt;",
        "'": "&#39;",
        '"': "&quot;",
      }[tag]
    )
  )
export default htmlEncode
